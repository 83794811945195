<template>
    <div style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit()">关联审批条件</el-button>
        <el-dialog :title="'关联审批条件'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <section class="main">
                <el-form :model="params" ref="form-ref">
                    <div class="radio-box">
                        <p>审批流实例条件</p>
                        <el-radio-group class="radio-ul" v-model="params.formItemId">
                            <el-radio v-for="(item, index) in processFormItemVos" :label="item.formItemId"
                                :disabled="item.bind" :key="index">{{ item.formItemName }}</el-radio>
                        </el-radio-group>
                    </div>
                    <el-button type="primary" @click="onSubmit('form-ref')">关联</el-button>
                    <div class="radio-box">
                        <p>工单参数</p>
                        <div class="radio-ul">
                            <el-radio-group class="radio-ul" v-model="params.templateParamId" @change="onWork">
                                <el-radio v-for="(item, index) in workSheetTemplateParamVoList" :label="item.templateParamId"
                                    :disabled="item.bind" :key="index">{{ item.paramName }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </el-form>
            </section>
            <section class="footer">
                <div class="footer-box">
                    <ul v-if="params.workFormItemRelationDto && params.workFormItemRelationDto.length > 0">
                        <el-tag class="tag-item" closable v-for="(tag,index) in params.workFormItemRelationDto"
                            @close="onCloseTag(index)" :key="index">
                            {{ tag.formItemName }} → {{ tag.paramName }}
                        </el-tag>
                    </ul>
                    <span v-else>暂无关联对象</span>
                </div>
            </section>

        </el-dialog>

    </div>
</template>
<script>

export default {
    props: ['row'],
    data() {
        return {
            editParamsShow: false,
            processFormItemVos: [], //预定义变量列表
            workSheetTemplateParamVoList: [], //工单参数
            workProjectTemplateParamVos: [], //项目参数
            workSheetTypeList: [], //工单类别列表
            workSheetTypeItem: "",
            params: {
                // projectTemplateId: getUrlParams().projectTemplateId, //模板ID
                // 关联审批
                formItemId: "", //预定义变量
                projectTemplateParamId: "", //项目参数
                templateParamId: "", //工单参数
                workFormItemRelationDto: [],
            },
        }
    },
    mounted() {

    },

    methods: {
        onEdit() {
            this.editParamsShow = true

        },

    // 【请求】工单类型配置列表
    getWorkSheetTypeList() {
      let data = {
        projectTemplateId: this.params.projectTemplateId,
      };
    //   utils.ajaxSilent(r.api + "/workProject/template/projectTemplateConfig", data, res => {
    //     if (res.success) {
    //       this.workSheetTypeList = res.data;
    //       this.workSheetTypeItem = res.data[0];
    //       this.getRelationProcessView(); //【请求】关联审批回显
    //     }
    //   })
    },

    // 【请求】关联审批回显
    getRelationProcessView() {
      let data = {
        projectTemplateId: this.params.projectTemplateId,
        workSheetType: this.workSheetTypeItem.workSheetType,
        processId: this.workSheetTypeItem.processId,
        sheetTemplateId: this.workSheetTypeItem.sheetTemplateId,
      };
    //   utils.ajaxSilent(r.api + "/workProject/template/relationProcessView", data, res => {
    //     if (res.success) {
    //       this.processFormItemVos = res.data.processFormItemVos; //预定义变量
    //       this.workSheetTemplateParamVoList = res.data.workSheetTemplateParamVoList; //工单参数
    //       this.workProjectTemplateParamVos = res.data.workProjectTemplateParamVos; //工单参数
    //       this.params.workFormItemRelationDto = res.data.paramRelationVos; //关联参数
    //     }
    //   })
    },

    // 【请求】关联审批
    getProcessViewSave() {
      let data = {
        projectTemplateId: this.params.projectTemplateId,
        workFormItemRelationDto: this.params.workFormItemRelationDto,
        workSheetType: this.workSheetTypeItem.workSheetType,
        processId: this.workSheetTypeItem.processId,
        sheetTemplateId: this.workSheetTypeItem.sheetTemplateId,
      };
    //   utils.ajaxSilent(r.api + "/workProject/template/relationProcess", data, res => {
    //     if (res.success) {
    //       layer.msg(res.msg);
    //       this.getRelationProcessView();
    //     }
    //   })
    },

    // 【监听】移除关联
    onCloseTag(index) {
        this.$confirm('是否要删除该关联吗？', '删除关联', {
                type: 'warning'
            }).then(() => {
                this.params.workFormItemRelationDto.splice(index, 1);
                this.getProcessViewSave();
                this.$message({
                    type: 'success',
                    message: '解除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            })
     
    },


    // 【监听】工单参数点击
    onWork() {
      this.params.projectTemplateParamId = "";
    },

    // 【监听】关联
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            formItemId: this.params.formItemId,
            projectTemplateParamId: this.params.projectTemplateParamId,
            templateParamId: this.params.templateParamId,
          };
          this.params.workFormItemRelationDto.push(obj);
          this.getProcessViewSave();
        } else {
          return false;
        }
      });
    },
  }

}
</script>
<style>
.main {
  height: 350px;
  padding: 15px;
  box-sizing: border-box;
}

.footer {
  height: 200px;
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
}

.footer-box {
  height: 100%;
  border: 1px solid #eee;
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;
}

.el-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.radio-box {
  border: 1px solid #eee;
  width: 250px;
  height: 300px;
  overflow: auto;
}

.radio-box p {
  padding: 10px 15px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  font-weight: bold;
}

.radio-ul {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.radio-ul .el-radio {
  margin: 5px 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #409EFF;
}

.tag-item {
  margin: 0 15px 15px 0;
}
</style>