<template>
    <div class="sort-table" style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit()">关联审批详情</el-button>
        <el-dialog :title="'参数排序'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <div class="head">
                <span>*请拖动行进行排序</span>
                <el-button type="primary" size="medium" plain @click.stop="add()">添加关联字段</el-button>
            </div>
            <el-table :data="tableData" id="sortTable" ref="sortTable" style="width: 100%" row-key="id" border>
                <el-table-column label="参数名称" prop="paramName">
                </el-table-column>
                <el-table-column label="参数类型">
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(paramType)[scope.row.paramType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="属性类型">
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(inputTypeObj)[scope.row.inputType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填">
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(yesOrNo)[scope.row.required - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="medium" @click.stop="dissolution(scope.row)">解除关联</el-button>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>
        <el-dialog :title="'选择关联字段'" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%"
            center>
            <div class="box">
                <el-checkbox-group v-model="formParams.projectFields" size="small">
                    <el-checkbox class="field-item" v-for="(item, index) in workProjectTemplateParamVoList" :key="index"
                        :label="item.paramName" border>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="pushCode">确定</el-button>
            </div>
        </el-dialog>


    </div>
</template>
<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import Sortable from 'sortablejs'
import { modelParamColumns } from "@/db/tableColumns"
import {
    paramType, yesOrNo, inputTypeObj
} from "@/db/objs"
export default {
    props: ['row'],
    data() {
        return {
            ObjToOpt, paramType, yesOrNo, inputTypeObj, modelParamColumns,
            tableData: [{
                id: 1,
                paramName: '12',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 2,
                paramName: '13',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 3,
                paramName: '14',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 4,
                paramName: '15',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            ],
            editParamsShow: false,
            editShow: false,
            workProjectTemplateParamVoList: [
                {
                    paramName: '工单名称',
                    projectTemplateParamId: 1,
                },
                {
                    paramName: '参数类型',
                    projectTemplateParamId: 2,
                }, {
                    paramName: '工单级别',
                    projectTemplateParamId: 3,
                },
                {
                    paramName: '参数名',
                    projectTemplateParamId: 9,
                }

            ],

            formParams: {
                projectFields: []
            }
        }
    },
    mounted() {

    },
    methods: {
        onEdit() {
            this.editParamsShow = true
            this.$nextTick(() => {
                this.initSort()
            })
        },
        initSort() {
            const el = document.querySelectorAll('#sortTable > .el-table__body-wrapper > table > tbody')[0]
            // const sortable = new Sortable(el, options);
            // 根据具体需求配置options配置项
            const sortable = new Sortable(el, {
                // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
                delay: 0,
                onEnd: (evt) => { // 监听拖动结束事件
                    // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
                    // 下面将拖拽后的顺序进行修改
                    const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
                    this.tableData.splice(evt.newIndex, 0, currRow)
                    let arr = []
                    this.tableData.forEach(item => {
                        arr.push(item.id)
                    })
                    //   this.sortLevelEquity(arr)
                }
            })
        },
        add() {
            this.editShow = true
        },
        dissolution() {
            this.$confirm('是否确定解除关联？', '解除关联', {
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '解除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            })
        },
        pushCode() {

        }

    }

}
</script>
<style lang="scss" scoped>
.el-table__row {
    cursor: move;
}

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.box {
    padding: 10px;
    border: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;

    .field-item {
        margin: 0px 15px 15px 0px !important;
    }
}
</style>