<template>
    <div class="people-list">
        <!-- 查詢 -->
        <section class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.status" placeholder="请选择工单模板" @change="reload" clearable>
                    <el-option v-for="item in ObjToOpt(workOrderType)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="search-R">
                <ModelInfo :title="'新增工单模板'" />
            </div>
        </section>
        <!-- 表格 -->
        <Table :tableData="tableData" :column="workModelColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="operation">
                <ModelInfo :title="'编辑'" :row="scope.row" />
                <SortTable :row="scope.row" />
                <Association :row="scope.row" />
                <Condition :row="scope.row" />
                <el-button type="text" size="medium" @click.stop="(scope.row)">删除</el-button>
            </template>
        </Table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
    </div>
</template>

<script>
import {
    getPeopleList,
} from "@/api/clean/clean"
import ModelInfo from "./child/modelInfo";
import SortTable from "./child/sortTable";
import Association from "./child/association";
import Condition from "./child/condition";
import { workModelColumns } from "@/db/tableColumns";
import { ObjToOpt } from "@/utils/utils";
import { workOrderType } from "@/db/objs";
export default {
    components: {
        ModelInfo, SortTable, Association,
        Condition
    },
    data() {
        return {
            workModelColumns,
            workOrderType,
            ObjToOpt,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            // table表格
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],//表格数据
            tableParams: {
                page: 1,
                pageSize: 10,
                kw: "",//查询关键字
                status: "",//店铺ID
                merchantId: "",//商户ID
                shopId: "",//店铺ID
            },
            // 弹框
            editShow: false, //弹框开关
            editTitle: "",//弹框标题

        }
    },
    created() {
        let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
        if (merchantShopId) {
            this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
            this.tableParams.shopId = JSON.parse(merchantShopId)[1];
           
        }
    },
    mounted() {
        this.getPeopleList();// 【请求】表格数据
    },
    methods: {
        // 【请求】表格数据
        getPeopleList() {
            let data = this.tableParams;
            getPeopleList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getPeopleList();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getPeopleList();
        },

        // 【监听】表格条数点击
        onSizeChange(pageSize) {
            this.tableParams.pageSize = pageSize;
            this.reload();
        },
        onDel(row){
            this.$confirm('是否确定删除该数据?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.people-list {
    padding: .15rem;
}

.table-footer {
    margin-top: .1rem;
    display: flex;
    justify-content: flex-end;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.search-L {
    display: flex;
}

.form-footer {
    display: flex;
    justify-content: center;
}

.code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.clear-head {
    width: .28rem;
    height: .28rem;
    border-radius: 50%;
    margin-right: .1rem;
}
</style>