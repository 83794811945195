<template>
    <div class="sort-table" style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit()">参数排序</el-button>
        <el-dialog :title="'参数排序'" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center>
            <el-table :data="tableData" id="sortTable" ref="sortTable" style="width: 100%" row-key="id" border>
                <el-table-column label="参数名称"  prop="paramName">
                </el-table-column>
                <el-table-column label="参数类型" >
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(paramType)[scope.row.paramType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="属性类型" >
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(inputTypeObj)[scope.row.inputType - 1].label }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="是否必填" >
                    <template slot-scope="scope">
                        <span>{{ ObjToOpt(yesOrNo)[scope.row.required - 1].label }}</span>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>

    </div>
</template>
<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import Sortable from 'sortablejs'
import { modelParamColumns } from "@/db/tableColumns"
import {
    paramType, yesOrNo, inputTypeObj
} from "@/db/objs"
export default {
    props: ['row'],
    data() {
        return {
            ObjToOpt, paramType, yesOrNo, inputTypeObj, modelParamColumns,
            tableData: [{
                id: 1,
                paramName: '12',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 2,
                paramName: '13',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 3,
                paramName: '14',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            {
                id: 4,
                paramName: '15',
                paramType: 1,
                inputType: 1,
                required: 1,
            },
            ],
            editParamsShow: false,
        }
    },
    mounted() {
     
    },
    methods: {
        onEdit() {
            this.editParamsShow = true
            this.$nextTick(() => {
                this.initSort()
            })
        },
        initSort() {
            const el = document.querySelectorAll('#sortTable > .el-table__body-wrapper > table > tbody')[0]
            // const sortable = new Sortable(el, options);
            // 根据具体需求配置options配置项
            const sortable = new Sortable(el, {
                // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
                delay: 0,
                onEnd: (evt) => { // 监听拖动结束事件
                    // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
                    // 下面将拖拽后的顺序进行修改
                    const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
                    this.tableData.splice(evt.newIndex, 0, currRow)
                    let arr = []
                    this.tableData.forEach(item => {
                        arr.push(item.id)
                    })
                    //   this.sortLevelEquity(arr)
                }
            })
        },
    }

}
</script>
<style>
.el-table__row{
    cursor: move;
}
</style>