<template>
    <div style="display: inline-block;margin-right: 10px;">
        <el-button type="text" size="medium" @click.stop="onEdit" v-if="title == '编辑'">{{ title }}</el-button>
        <el-button type="primary" size="medium" @click.stop="onAdd" v-else>{{ title }}</el-button>
        <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%"
            center>
            <!-- 表单提交 -->
            <el-form :model="params" ref="form-ref" label-width="100px" :inline="true">
                <el-form-item label="工单类型" prop="workProjectType">
                    <el-select v-model="params.workProjectType" placeholder="请选择工单类型">
                        <el-option v-for="item in ObjToOpt(WOT)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板名称" prop="workProjectName"
                    :rules="[{ required: true, message: '请输入类型名称', trigger: 'blur' }]">
                    <el-input v-model.trim="params.workProjectName" autocomplete="off" maxlength="20"
                        placeholder="请输入名称"></el-input>
                </el-form-item>

                <el-form-item label="关联审批流程" prop="sex">
                    <el-select v-model="params.sex" placeholder="请选择审批流程">
                        <el-option v-for="item in processList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                <span style="color: #000;">配置参数：</span>
                <el-button type="primary" plain size="medium" @click.stop="addparams()">新增参数</el-button>
            </div>
            <Table :tableData="tableData" :column="modelParamColumns">
                <template slot-scope="scope" slot="paramTypeSlot">
                    <span>{{ ObjToOpt(paramType)[scope.row.paramType - 1].label }}</span>
                </template>
                <template slot-scope="scope" slot="inputTypeSlot">
                    <span>{{ ObjToOpt(inputTypeObj)[scope.row.inputType - 1].label }}</span>
                </template>
                <template slot-scope="scope" slot="requiredSlot">
                    <span>{{ ObjToOpt(yesOrNo)[scope.row.required - 1].label }}</span>
                </template>
                <template slot-scope="scope" slot="operation">
                    <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="medium" @click.stop="onDel(scope)">删除</el-button>
                </template>
            </Table>
            <div slot="footer">
                <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="editParamsTitle" :visible.sync="editParamsShow" :close-on-click-modal="false" append-to-body
            width="50%" center @closed="paramsForm = {}">
            <el-form :model="paramsForm" ref="form-params-ref" label-width="140px" :inline="true">
                <el-form-item label="参数名称" prop="paramName"
                    :rules="[{ required: true, message: '请输入参数名称', trigger: 'blur' }]">
                    <el-input v-model.trim="paramsForm.paramName" autocomplete="off" maxlength="20"
                        placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="参数类型" prop="paramType">
                    <el-select v-model="paramsForm.paramType" placeholder="请选择参数类型" @change="changeParamType">
                        <el-option v-for="item in ObjToOpt(paramType)" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <template v-if="paramsForm.paramType && paramsForm.paramType != ''">
                    <div style="margin-left: 70px;margin-bottom: 20px;">参数属性</div>
                    <el-form-item label="类型" prop="inputType">
                        <el-select v-model="paramsForm.inputType" placeholder="请选择输入类型" :disabled="[3,4].includes(paramsForm.paramType)">
                            <el-option v-for="item in ObjToOpt(inputTypeOpt[paramsForm.paramType])" :label="item.label"
                                :value="item.value" :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上传文件类型" prop="uploadType" v-if="paramsForm.paramType == 4">
                        <el-select v-model="paramsForm.uploadType" placeholder="请选择类型">
                            <el-option v-for="item in ObjToOpt(fileType)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="限制数量" prop="limitLength" v-if="paramsForm.paramType == 4">
                        <el-input v-model="paramsForm.limitLength" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="限制范围" prop="minlength" v-if="paramsForm.paramType == 1">
                        <div style="display: flex;">
                            <el-input v-model="paramsForm.minlength" placeholder="最小值"
                                style="width: 50%;"></el-input><span style="margin: 0 10px;"> - </span>
                            <el-input v-model="paramsForm.maxlength" placeholder="最大值" style="width: 50%;"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="是否可搜索" prop="canSearch" v-if="paramsForm.paramType == 2">
                        <el-select v-model="paramsForm.canSearch" placeholder="请选择类型">
                            <el-option v-for="item in ObjToOpt(yesOrNo)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="占位文本" prop="placeholder"
                        :rules="[{ required: true, message: '请输入占位文本', trigger: 'blur' }]"
                        v-if="[1, 2, 3].includes(paramsForm.paramType)">
                        <el-input v-model.trim="paramsForm.placeholder" autocomplete="off" maxlength="20"
                            placeholder="请输入占位文本"></el-input>
                    </el-form-item>
                    <el-form-item label="是否禁用" prop="disabled">
                        <el-select v-model="paramsForm.disabled" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(yesOrNo)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否可选当日之前" prop="dateLimit" v-if="paramsForm.paramType == 3">
                        <el-select v-model="paramsForm.dateLimit" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(yesOrNo)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="默认值" prop="defaultVal" v-if="[1, 2].includes(paramsForm.paramType)">
                        <el-input v-model.trim="paramsForm.defaultVal" autocomplete="off" maxlength="20"
                            placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填" prop="required" v-if="[1, 2, 3].includes(paramsForm.paramType)">
                        <el-select v-model="paramsForm.required" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(yesOrNo)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="数据源" prop="sourceType" v-if="paramsForm.paramType == 2"
                        :rules="[{ required: true, message: '请选择数据源', trigger: 'change' }]">
                        <el-select v-model="paramsForm.sourceType" placeholder="请选择">
                            <el-option v-for="item in ObjToOpt(dataSource)" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="onSubmitParams('form-params-ref')">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    ObjToOpt,
} from "@/utils/utils"
import { modelParamColumns } from "@/db/tableColumns"
import {
    WOT, paramType, yesOrNo, inputTypeOpt, inputTypeObj, dataSource, fileType
} from "@/db/objs"
export default {
    props: ['title', 'row'],
    data() {
        return {
            ObjToOpt, WOT, modelParamColumns, paramType, yesOrNo, inputTypeOpt, inputTypeObj, dataSource, fileType,
            editShow: false,
            params: {
                workProjectName: '',
            },
            editTitle: '新建工单模板',
            processList: [],//关联审批流程配置列表
            tableData: [
                {
                    paramName: '12',
                    paramType: 1,
                    inputType: 1,
                    required: 1,
                }
            ],
            editParamsTitle: '新增参数',
            editParamsShow: false,
            paramsForm: {}
        }
    },
    methods: {
        onAdd() {
            this.editShow = true
            this.editTitle = '新建工单模板'
        },
        onEdit() {
            this.editShow = true
            this.editTitle = '编辑工单模板'
            this.params = this.row
        },
        onDel(scope) {
            this.tableData.splice(scope.index, 1)
        },
        changeParamType(val) {
            switch (val) {
                case 1:
                this.paramsForm.inputType = ''
                    break;
                case 2:
                    this.paramsForm.inputType = 5
                    this.paramsForm.canSearch = 2
                    this.paramsForm.disabled = 2
                    this.paramsForm.required = 2
                    break;
                case 3:
                this.paramsForm.inputType = 7
                    this.paramsFormdateLimit = 1
                    this.paramsForm.disabled = 2
                    this.paramsForm.required = 2
                    break;
                case 4:
                this.paramsForm.inputType = 8
                    this.paramsForm.required = 2
                    break;
            }
        },
   
        addparams() {
            this.editParamsShow = true
            this.editParamsTitle = '新增参数'
            console.log(this.paramsForm)
        },
        editParams() {
            this.editParamsShow = true
            this.editParamsTitle = '编辑参数'

        },
        onSubmitParams(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.tableData.push(this.paramsForm)
                    console.log(this.tableData)
                    this.editParamsShow = false
                } else {
                    return false;
                }
            })
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                } else {
                    return false;
                }
            })
        },
    }

}
</script>

<style></style>